import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'air-traffic-control',
  'IconAirTrafficControl',
  [
    ['path', { d: 'M11 3h2', key: 'svg-0' }],
    ['path', { d: 'M12 3v3', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M5.998 6h12.004a2 2 0 0 1 1.916 2.575l-1.8 6a2 2 0 0 1 -1.916 1.425h-8.404a2 2 0 0 1 -1.916 -1.425l-1.8 -6a2 2 0 0 1 1.916 -2.575z',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M8.5 6l1.5 10v5', key: 'svg-3' }],
    ['path', { d: 'M15.5 6l-1.5 10v5', key: 'svg-4' }],
  ],
);
