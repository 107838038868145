import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'box-align-left-filled',
  'IconBoxAlignLeftFilled',
  [
    [
      'path',
      {
        d: 'M10.002 3.003h-5a2 2 0 0 0 -2 2v14a2 2 0 0 0 2 2h5a1 1 0 0 0 1 -1v-16a1 1 0 0 0 -1 -1z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M15.002 19.003a1 1 0 0 1 .117 1.993l-.127 .007a1 1 0 0 1 -.117 -1.993l.127 -.007z',
        fill: 'currentColor',
        key: 'svg-1',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M20.003 19.003a1 1 0 0 1 .117 1.993l-.128 .007a1 1 0 0 1 -.117 -1.993l.128 -.007z',
        fill: 'currentColor',
        key: 'svg-2',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M20.003 14.002a1 1 0 0 1 .117 1.993l-.128 .007a1 1 0 0 1 -.117 -1.993l.128 -.007z',
        fill: 'currentColor',
        key: 'svg-3',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M20.003 8.002a1 1 0 0 1 .117 1.993l-.128 .007a1 1 0 0 1 -.117 -1.993l.128 -.007z',
        fill: 'currentColor',
        key: 'svg-4',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M20.003 3.002a1 1 0 0 1 .117 1.993l-.128 .007a1 1 0 0 1 -.117 -1.993l.128 -.007z',
        fill: 'currentColor',
        key: 'svg-5',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M15.002 3.002a1 1 0 0 1 .117 1.993l-.127 .007a1 1 0 0 1 -.117 -1.993l.127 -.007z',
        fill: 'currentColor',
        key: 'svg-6',
        strokeWidth: '0',
      },
    ],
  ],
);
