import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'align-box-center-top',
  'IconAlignBoxCenterTop',
  [
    [
      'path',
      {
        d: 'M3 19v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M11 13h2', key: 'svg-1' }],
    ['path', { d: 'M9 10h6', key: 'svg-2' }],
    ['path', { d: 'M10 7h4', key: 'svg-3' }],
  ],
);
