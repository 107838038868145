import createReactComponent from '../createReactComponent';
export default createReactComponent('currency-florin', 'IconCurrencyFlorin', [
  ['path', { d: 'M8 12h8', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M7 19c1.213 0 2.31 -.723 2.788 -1.838l4.424 -10.324a3.033 3.033 0 0 1 2.788 -1.838',
      key: 'svg-1',
    },
  ],
]);
