import createReactComponent from '../createReactComponent';
export default createReactComponent('capsule-filled', 'IconCapsuleFilled', [
  [
    'path',
    {
      d: 'M12 2l-.243 .004a7.004 7.004 0 0 0 -6.757 6.996v6a7 7 0 0 0 7 7l.243 -.004a7.004 7.004 0 0 0 6.757 -6.996v-6a7 7 0 0 0 -7 -7z',
      fill: 'currentColor',
      key: 'svg-0',
      strokeWidth: '0',
    },
  ],
]);
