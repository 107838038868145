import createReactComponent from '../createReactComponent';
export default createReactComponent('capture-filled', 'IconCaptureFilled', [
  [
    'path',
    {
      d: 'M8 3a1 1 0 0 1 .117 1.993l-.117 .007h-2a1 1 0 0 0 -.993 .883l-.007 .117v2a1 1 0 0 1 -1.993 .117l-.007 -.117v-2a3 3 0 0 1 2.824 -2.995l.176 -.005h2z',
      fill: 'currentColor',
      key: 'svg-0',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M4 15a1 1 0 0 1 .993 .883l.007 .117v2a1 1 0 0 0 .883 .993l.117 .007h2a1 1 0 0 1 .117 1.993l-.117 .007h-2a3 3 0 0 1 -2.995 -2.824l-.005 -.176v-2a1 1 0 0 1 1 -1z',
      fill: 'currentColor',
      key: 'svg-1',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M18 3a3 3 0 0 1 2.995 2.824l.005 .176v2a1 1 0 0 1 -1.993 .117l-.007 -.117v-2a1 1 0 0 0 -.883 -.993l-.117 -.007h-2a1 1 0 0 1 -.117 -1.993l.117 -.007h2z',
      fill: 'currentColor',
      key: 'svg-2',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M20 15a1 1 0 0 1 .993 .883l.007 .117v2a3 3 0 0 1 -2.824 2.995l-.176 .005h-2a1 1 0 0 1 -.117 -1.993l.117 -.007h2a1 1 0 0 0 .993 -.883l.007 -.117v-2a1 1 0 0 1 1 -1z',
      fill: 'currentColor',
      key: 'svg-3',
      strokeWidth: '0',
    },
  ],
  [
    'path',
    {
      d: 'M12 8a4 4 0 1 1 -3.995 4.2l-.005 -.2l.005 -.2a4 4 0 0 1 3.995 -3.8z',
      fill: 'currentColor',
      key: 'svg-4',
      strokeWidth: '0',
    },
  ],
]);
