import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-craft', 'IconBrandCraft', [
  [
    'path',
    {
      d: 'M20 4h-8a8 8 0 1 0 0 16h8a8 8 0 0 0 -8 -8a8 8 0 0 0 8 -8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 12h8', key: 'svg-1' }],
  ['path', { d: 'M12 4v16', key: 'svg-2' }],
]);
