import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'box-align-top-filled',
  'IconBoxAlignTopFilled',
  [
    [
      'path',
      {
        d: 'M19 3.005h-14a2 2 0 0 0 -2 2v5a1 1 0 0 0 1 1h16a1 1 0 0 0 1 -1v-5a2 2 0 0 0 -2 -2z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M4 13.995a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-1',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M4 18.995a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-2',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M9 18.995a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-3',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M15 18.995a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-4',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M20 18.995a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-5',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M20 13.995a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-6',
        strokeWidth: '0',
      },
    ],
  ],
);
