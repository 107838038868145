import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'currency-iranian-rial',
  'IconCurrencyIranianRial',
  [
    [
      'path',
      { d: 'M9 4v9a2 2 0 0 1 -2 2h-1a3 3 0 0 1 -3 -3v-1', key: 'svg-0' },
    ],
    ['path', { d: 'M12 5v8a1 1 0 0 0 1 1h1a2 2 0 0 0 2 -2v-1', key: 'svg-1' }],
    [
      'path',
      { d: 'M21 14v1.096a5 5 0 0 1 -3.787 4.85l-.213 .054', key: 'svg-2' },
    ],
    ['path', { d: 'M11 18h.01', key: 'svg-3' }],
    ['path', { d: 'M14 18h.01', key: 'svg-4' }],
  ],
);
