import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-threads', 'IconBrandThreads', [
  [
    'path',
    {
      d: 'M19 7.5c-1.333 -3 -3.667 -4.5 -7 -4.5c-5 0 -8 2.5 -8 9s3.5 9 8 9s7 -3 7 -5s-1 -5 -7 -5c-2.5 0 -3 1.25 -3 2.5c0 1.5 1 2.5 2.5 2.5c2.5 0 3.5 -1.5 3.5 -5s-2 -4 -3 -4s-1.833 .333 -2.5 1',
      key: 'svg-0',
    },
  ],
]);
