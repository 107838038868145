import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'capsule-horizontal-filled',
  'IconCapsuleHorizontalFilled',
  [
    [
      'path',
      {
        d: 'M15 5h-6a7 7 0 1 0 0 14h6a7 7 0 0 0 7 -7l-.007 -.303a7 7 0 0 0 -6.993 -6.697z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
  ],
);
