import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'bookmark-question',
  'IconBookmarkQuestion',
  [
    [
      'path',
      {
        d: 'M15.006 18.804l-3.006 -1.804l-5 3v-14a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v5.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M19 22v.01', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
        key: 'svg-2',
      },
    ],
  ],
);
