import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-aws', 'IconBrandAws', [
  [
    'path',
    {
      d: 'M17 18.5a15.198 15.198 0 0 1 -7.37 1.44a14.62 14.62 0 0 1 -6.63 -2.94',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M19.5 21c.907 -1.411 1.451 -3.323 1.5 -5c-1.197 -.773 -2.577 -.935 -4 -1',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 11v-4.5a1.5 1.5 0 0 1 3 0v4.5', key: 'svg-2' }],
  ['path', { d: 'M3 9h3', key: 'svg-3' }],
  ['path', { d: 'M9 5l1.2 6l1.8 -4l1.8 4l1.2 -6', key: 'svg-4' }],
  [
    'path',
    {
      d: 'M18 10.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75',
      key: 'svg-5',
    },
  ],
]);
