import createReactComponent from '../createReactComponent';
export default createReactComponent('basket-star', 'IconBasketStar', [
  ['path', { d: 'M17 10l-2 -6', key: 'svg-0' }],
  ['path', { d: 'M7 10l2 -6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M10.5 20h-3.256a3 3 0 0 1 -2.965 -2.544l-1.255 -7.152a2 2 0 0 1 1.977 -2.304h13.999a2 2 0 0 1 1.977 2.304l-.133 .757',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M13.596 12.794a2 2 0 0 0 -3.377 2.116', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z',
      key: 'svg-4',
    },
  ],
]);
