import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-microsoft-teams',
  'IconBrandMicrosoftTeams',
  [
    ['path', { d: 'M3 7h10v10h-10z', key: 'svg-0' }],
    ['path', { d: 'M6 10h4', key: 'svg-1' }],
    ['path', { d: 'M8 10v4', key: 'svg-2' }],
    [
      'path',
      {
        d: 'M8.104 17c.47 2.274 2.483 4 4.896 4a5 5 0 0 0 5 -5v-7h-5',
        key: 'svg-3',
      },
    ],
    ['path', { d: 'M18 18a4 4 0 0 0 4 -4v-5h-4', key: 'svg-4' }],
    ['path', { d: 'M13.003 8.83a3 3 0 1 0 -1.833 -1.833', key: 'svg-5' }],
    ['path', { d: 'M15.83 8.36a2.5 2.5 0 1 0 .594 -4.117', key: 'svg-6' }],
  ],
);
