import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'align-box-left-stretch',
  'IconAlignBoxLeftStretch',
  [
    [
      'path',
      {
        d: 'M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 17h-2', key: 'svg-1' }],
    ['path', { d: 'M13 12h-6', key: 'svg-2' }],
    ['path', { d: 'M11 7h-4', key: 'svg-3' }],
  ],
);
