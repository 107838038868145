import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-digg', 'IconBrandDigg', [
  ['path', { d: 'M6 15h-3v-4h3', key: 'svg-0' }],
  ['path', { d: 'M15 15h-3v-4h3', key: 'svg-1' }],
  ['path', { d: 'M9 15v-4', key: 'svg-2' }],
  ['path', { d: 'M15 11v7h-3', key: 'svg-3' }],
  ['path', { d: 'M6 7v8', key: 'svg-4' }],
  ['path', { d: 'M21 15h-3v-4h3', key: 'svg-5' }],
  ['path', { d: 'M21 11v7h-3', key: 'svg-6' }],
]);
