import createReactComponent from '../createReactComponent';
export default createReactComponent('bookmark-plus', 'IconBookmarkPlus', [
  [
    'path',
    {
      d: 'M12.357 17.214l-.357 -.214l-5 3v-14a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v6.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-1' }],
  ['path', { d: 'M19 16v6', key: 'svg-2' }],
]);
