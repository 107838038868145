import createReactComponent from '../createReactComponent';
export default createReactComponent('box-align-top', 'IconBoxAlignTop', [
  [
    'path',
    {
      d: 'M4 10.005h16v-5a1 1 0 0 0 -1 -1h-14a1 1 0 0 0 -1 1v5z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 15.005v-.01', key: 'svg-1' }],
  ['path', { d: 'M4 20.005v-.01', key: 'svg-2' }],
  ['path', { d: 'M9 20.005v-.01', key: 'svg-3' }],
  ['path', { d: 'M15 20.005v-.01', key: 'svg-4' }],
  ['path', { d: 'M20 20.005v-.01', key: 'svg-5' }],
  ['path', { d: 'M20 15.005v-.01', key: 'svg-6' }],
]);
