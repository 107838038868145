import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-rust', 'IconBrandRust', [
  [
    'path',
    {
      d: 'M10.139 3.463c.473 -1.95 3.249 -1.95 3.722 0a1.916 1.916 0 0 0 2.859 1.185c1.714 -1.045 3.678 .918 2.633 2.633a1.916 1.916 0 0 0 1.184 2.858c1.95 .473 1.95 3.249 0 3.722a1.916 1.916 0 0 0 -1.185 2.859c1.045 1.714 -.918 3.678 -2.633 2.633a1.916 1.916 0 0 0 -2.858 1.184c-.473 1.95 -3.249 1.95 -3.722 0a1.916 1.916 0 0 0 -2.859 -1.185c-1.714 1.045 -3.678 -.918 -2.633 -2.633a1.916 1.916 0 0 0 -1.184 -2.858c-1.95 -.473 -1.95 -3.249 0 -3.722a1.916 1.916 0 0 0 1.185 -2.859c-1.045 -1.714 .918 -3.678 2.633 -2.633a1.914 1.914 0 0 0 2.858 -1.184z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 12h6a2 2 0 1 0 0 -4h-6v8v-4z', key: 'svg-1' }],
  ['path', { d: 'M19 16h-2a2 2 0 0 1 -2 -2a2 2 0 0 0 -2 -2h-1', key: 'svg-2' }],
  ['path', { d: 'M9 8h-4', key: 'svg-3' }],
  ['path', { d: 'M5 16h4', key: 'svg-4' }],
]);
