import createReactComponent from '../createReactComponent';
export default createReactComponent('bread', 'IconBread', [
  [
    'path',
    {
      d: 'M18 4a3 3 0 0 1 2 5.235v8.765a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-8.764a3 3 0 0 1 1.824 -5.231h12.176v-.005z',
      key: 'svg-0',
    },
  ],
]);
