import createReactComponent from '../createReactComponent';
export default createReactComponent('basket-up', 'IconBasketUp', [
  ['path', { d: 'M17 10l-2 -6', key: 'svg-0' }],
  ['path', { d: 'M7 10l2 -6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 20h-4.756a3 3 0 0 1 -2.965 -2.544l-1.255 -7.152a2 2 0 0 1 1.977 -2.304h13.999a2 2 0 0 1 1.977 2.304l-.358 2.04',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M10 14a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-3' }],
  ['path', { d: 'M19 22v-6', key: 'svg-4' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-5' }],
]);
