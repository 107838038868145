import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-terraform', 'IconBrandTerraform', [
  [
    'path',
    {
      d: 'M15 15.5l-11.476 -6.216a1 1 0 0 1 -.524 -.88v-4.054a1.35 1.35 0 0 1 2.03 -1.166l9.97 5.816v10.65a1.35 1.35 0 0 1 -2.03 1.166l-3.474 -2.027a1 1 0 0 1 -.496 -.863v-11.926',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M15 15.5l5.504 -3.21a1 1 0 0 0 .496 -.864v-3.576a1.35 1.35 0 0 0 -2.03 -1.166l-3.97 2.316',
      key: 'svg-1',
    },
  ],
]);
