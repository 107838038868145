import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-cloudflare', 'IconBrandCloudflare', [
  [
    'path',
    {
      d: 'M13.031 7.007c2.469 -.007 3.295 1.293 3.969 2.993c4 0 4.994 3.825 5 6h-20c-.001 -1.64 1.36 -2.954 3 -3c0 -1.5 1 -3 3 -3c.66 -1.942 2.562 -2.986 5.031 -2.993z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 13h6', key: 'svg-1' }],
  ['path', { d: 'M17 10l-2.5 6', key: 'svg-2' }],
]);
