import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'capsule-horizontal',
  'IconCapsuleHorizontal',
  [
    [
      'path',
      {
        d: 'M3 6m0 6a6 6 0 0 1 6 -6h6a6 6 0 0 1 6 6v0a6 6 0 0 1 -6 6h-6a6 6 0 0 1 -6 -6z',
        key: 'svg-0',
      },
    ],
  ],
);
