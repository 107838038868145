import createReactComponent from '../createReactComponent';
export default createReactComponent('briefcase-2', 'IconBriefcase2', [
  [
    'path',
    {
      d: 'M3 9a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2', key: 'svg-1' }],
]);
