import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-xdeep', 'IconBrandXdeep', [
  [
    'path',
    {
      d: 'M14.401 8.398l1.599 -2.398h5l-4 6l4 6h-5l-8 -12h-5l4 6l-4 6h5l1.596 -2.393',
      key: 'svg-0',
    },
  ],
]);
