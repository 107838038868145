import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brightness-down-filled',
  'IconBrightnessDownFilled',
  [
    [
      'path',
      {
        d: 'M12 8a4 4 0 1 1 -3.995 4.2l-.005 -.2l.005 -.2a4 4 0 0 1 3.995 -3.8z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M12 4a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-1',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M17 6a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-2',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M19 11a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-3',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M17 16a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-4',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M12 18a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-5',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M7 16a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-6',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M5 11a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-7',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M7 6a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-8',
        strokeWidth: '0',
      },
    ],
  ],
);
