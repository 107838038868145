import createReactComponent from '../createReactComponent';
export default createReactComponent('capsule', 'IconCapsule', [
  [
    'path',
    {
      d: 'M6 3m0 6a6 6 0 0 1 6 -6h0a6 6 0 0 1 6 6v6a6 6 0 0 1 -6 6h0a6 6 0 0 1 -6 -6z',
      key: 'svg-0',
    },
  ],
]);
