import createReactComponent from '../createReactComponent';
export default createReactComponent('basket-discount', 'IconBasketDiscount', [
  ['path', { d: 'M17 10l-2 -6', key: 'svg-0' }],
  ['path', { d: 'M7 10l2 -6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12.5 20h-5.256a3 3 0 0 1 -2.965 -2.544l-1.255 -7.152a2 2 0 0 1 1.977 -2.304h13.999a2 2 0 0 1 1.977 2.304l-.394 2.248',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M13.856 13.254a2 2 0 1 0 -1.856 2.746', key: 'svg-3' }],
  ['path', { d: 'M16 21l5 -5', key: 'svg-4' }],
  ['path', { d: 'M21 21v.01', key: 'svg-5' }],
  ['path', { d: 'M16 16v.01', key: 'svg-6' }],
]);
