import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brand-electronic-arts',
  'IconBrandElectronicArts',
  [
    ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
    ['path', { d: 'M17.5 15l-3 -6l-3 6h-5l1.5 -3', key: 'svg-1' }],
    ['path', { d: 'M17 14h-2', key: 'svg-2' }],
    ['path', { d: 'M6.5 12h3.5', key: 'svg-3' }],
    ['path', { d: 'M8 9h3', key: 'svg-4' }],
  ],
);
