import createReactComponent from '../createReactComponent';
export default createReactComponent('basket-search', 'IconBasketSearch', [
  ['path', { d: 'M17 10l-2 -6', key: 'svg-0' }],
  ['path', { d: 'M7 10l2 -6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M11 20h-3.756a3 3 0 0 1 -2.965 -2.544l-1.255 -7.152a2 2 0 0 1 1.977 -2.304h13.999a2 2 0 0 1 1.977 2.304l-.215 1.227',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M13.483 12.658a2 2 0 1 0 -2.162 3.224', key: 'svg-3' }],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-4' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-5' }],
]);
