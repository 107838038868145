import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'brightness-up-filled',
  'IconBrightnessUpFilled',
  [
    [
      'path',
      {
        d: 'M12 8a4 4 0 1 1 -3.995 4.2l-.005 -.2l.005 -.2a4 4 0 0 1 3.995 -3.8z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M12 2a1 1 0 0 1 .993 .883l.007 .117v2a1 1 0 0 1 -1.993 .117l-.007 -.117v-2a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-1',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M17.693 4.893a1 1 0 0 1 1.497 1.32l-.083 .094l-1.4 1.4a1 1 0 0 1 -1.497 -1.32l.083 -.094l1.4 -1.4z',
        fill: 'currentColor',
        key: 'svg-2',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M21 11a1 1 0 0 1 .117 1.993l-.117 .007h-2a1 1 0 0 1 -.117 -1.993l.117 -.007h2z',
        fill: 'currentColor',
        key: 'svg-3',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M16.293 16.293a1 1 0 0 1 1.32 -.083l.094 .083l1.4 1.4a1 1 0 0 1 -1.32 1.497l-.094 -.083l-1.4 -1.4a1 1 0 0 1 0 -1.414z',
        fill: 'currentColor',
        key: 'svg-4',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M12 18a1 1 0 0 1 .993 .883l.007 .117v2a1 1 0 0 1 -1.993 .117l-.007 -.117v-2a1 1 0 0 1 1 -1z',
        fill: 'currentColor',
        key: 'svg-5',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M6.293 16.293a1 1 0 0 1 1.497 1.32l-.083 .094l-1.4 1.4a1 1 0 0 1 -1.497 -1.32l.083 -.094l1.4 -1.4z',
        fill: 'currentColor',
        key: 'svg-6',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M6 11a1 1 0 0 1 .117 1.993l-.117 .007h-2a1 1 0 0 1 -.117 -1.993l.117 -.007h2z',
        fill: 'currentColor',
        key: 'svg-7',
        strokeWidth: '0',
      },
    ],
    [
      'path',
      {
        d: 'M4.893 4.893a1 1 0 0 1 1.32 -.083l.094 .083l1.4 1.4a1 1 0 0 1 -1.32 1.497l-.094 -.083l-1.4 -1.4a1 1 0 0 1 0 -1.414z',
        fill: 'currentColor',
        key: 'svg-8',
        strokeWidth: '0',
      },
    ],
  ],
);
