import createReactComponent from '../createReactComponent';
export default createReactComponent('cone-plus', 'IconConePlus', [
  [
    'path',
    {
      d: 'M18.03 12.022l-5.16 -9.515a1 1 0 0 0 -1.74 0l-8.13 14.99v.5c0 1.66 4.03 3.003 9 3.003c.17 0 .34 -.002 .508 -.005',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-1' }],
  ['path', { d: 'M19 16v6', key: 'svg-2' }],
]);
