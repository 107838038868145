import createReactComponent from '../createReactComponent';
export default createReactComponent('basket-pause', 'IconBasketPause', [
  ['path', { d: 'M17 10l-2 -6', key: 'svg-0' }],
  ['path', { d: 'M7 10l2 -6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M13 20h-5.756a3 3 0 0 1 -2.965 -2.544l-1.255 -7.152a2 2 0 0 1 1.977 -2.304h13.999a2 2 0 0 1 1.977 2.304l-.478 2.725',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M10 14a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-3' }],
  ['path', { d: 'M17 17v5', key: 'svg-4' }],
  ['path', { d: 'M21 17v5', key: 'svg-5' }],
]);
